import type { DestinationRoute, RoutingMap } from '@/constants/routing'
import { Routing } from '@/constants/routing'

/**
 *
 * @param pageSlug should be one of the products or product in active locale
 * @param locale active locale
 * @returns pageType "category" | "product"
 */
export const getPageType = (pageSlug: string, locale: string): DestinationRoute | undefined => {
  if (!pageSlug || !locale) {
    return undefined
  }
  const localeRouting = (Routing)[locale.toLocaleLowerCase()]

  if (!localeRouting || !Object.keys(localeRouting).length) {
    return undefined
  }

  return localeRouting[pageSlug]
}
