import { getEnv } from '@/envs'

import { isNotEmpty } from './isEmpty'

export const generateAlgoliaIndexName = ({
  country,
  sortBy,
}: {
  country: string
  sortBy?: string
}) => {
  let indexName = `${getEnv('NEXT_PUBLIC_ALGOLIA_INDEX_PREFIX')}_${country.toLowerCase()}`

  if (isNotEmpty(sortBy)) {
    indexName = `${indexName}_${sortBy}`
  }

  return indexName
}
