import logger from '@knauf-group/ct-shared-nextjs/logger'

import { InvertedRouting, RootPath } from '@/constants/routing'
import type {
  CategoryPathCalculationParams,
  HomePathParams,
  ProductPathCalculationParams,
} from '@/types/routing'

import { isNotEmpty } from './isEmpty'

export const getHomePath = (params: HomePathParams) => `/${params.locale}`

export const getCategoryPagePath = (params: CategoryPathCalculationParams) => {
  const { locale, categorySlug, search } = params

  const localeLowerCase = locale.toLowerCase()

  const routing = InvertedRouting[localeLowerCase]

  let errorMessage: string | undefined
  if (!locale) {
    errorMessage = `Missing locale info in getCategoryPagePath. Params: ${JSON.stringify(
      params,
    )}`
  } else if (!routing) {
    errorMessage = `getCategoryPagePath: '${localeLowerCase}' does not exist in 'InvertedRouting'`
  } else if (!routing.category) {
    errorMessage = `getCategoryPagePath: 'category' key does not exist in 'InvertedRouting[${localeLowerCase}]': ${JSON.stringify(
      routing,
    )}`
  }

  if (errorMessage) {
    logger.error(errorMessage)
    throw new Error(errorMessage)
  }

  const { category } = routing

  const normalizedSearch = isNotEmpty(search) ? search : ''

  if (isNotEmpty(categorySlug))
    return `/${RootPath}/${category}/${categorySlug}${normalizedSearch}` as const

  return `/${RootPath}/${category}${normalizedSearch}` as const
}

export const getProductDetailsPath = (params: ProductPathCalculationParams) => {
  const { locale, productSlug } = params

  const localeLowerCase = locale.toLowerCase()
  
  const routing = InvertedRouting[localeLowerCase]

  let errorMessage: string | undefined
  if (!locale) {
    errorMessage = `Missing locale info in getProductDetailsPath. Params: ${JSON.stringify(
      params,
    )}`
  } else if (!routing) {
    errorMessage = `getProductDetailsPath: '${localeLowerCase}' does not exist in 'InvertedRouting'`
  } else if (!routing.product) {
    errorMessage = `getProductDetailsPath: 'product' key does not exist in 'InvertedRouting[${localeLowerCase}]': ${JSON.stringify(
      routing,
    )}`
  }

  if (errorMessage) {
    logger.error(errorMessage)
    throw new Error(errorMessage)
  }

  const rootPath = RootPath
  const { product } = routing

  return `/${rootPath}/${product}/${productSlug}` as const
}
