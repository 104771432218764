import { useMediaQuery } from '@mui/material'
import type { Theme } from '@mui/material/styles'

export const useBreakpoints = () => {
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'))
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  return {
    isDesktop,
    isNotDesktop: !isDesktop,
    isMobile,
  }
}
